@use 'sass:color';

$primary-brand-default: #309fe3;
$primary-brand-default-lighten: color.adjust(
  $primary-brand-default,
  $lightness: 30%
);
$primary-brand-default-darken: color.adjust(
  $primary-brand-default,
  $lightness: -20%
);
$secondary-brand: #212c41;

$cmv-blue-grey: #96aec3;
$cmv-slate: #3d475f;
$cmv-dim-gray: #4b4b4b;
$cmv-gray: #6f6f6f;
$cmv-dark-gray: #979797;
$cmv-silver: #cbcbcb;
$cmv-light-gray: #e1e1e1;
$cmv-gainsboro: #eaeaea;
$cmv-white: #ffffff;
$cmv-dark-blue: #111622;
$cmv-second-dark-blue: #111622;
$cmv-third-dark-blue: #1b2436;

$empty-thumbnail-gray: #a7a7a9;
$hover: #f2f2f2;

$cmv-colors: (
  primary-brand: $primary-brand-default,
  secondary-brand: $secondary-brand,
  cmv-dim-gray: $cmv-dim-gray,
  cmv-gray: $cmv-gray,
  cmv-dark-gray: $cmv-dark-gray,
  cmv-silver: $cmv-silver,
  cmv-light-gray: $cmv-light-gray,
  cmv-gainsboro: $cmv-gainsboro,
  cmv-white: $cmv-white,
  cmv-blue-grey: $cmv-blue-grey,
  cmv-slate: $cmv-slate,
);
