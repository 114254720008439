@use 'variables' as vars;

$xxxl-break-point: 1781px;
$xxl-break-point: 1441px;
$xl-break-point: 1025px;
$l-break-point: 769px;
$m-break-point: 601px;
$s-break-point: 376px;

$minimum-height-landscape-break-point: 0;
$mobile-landscape-height-break-point: 500px;
$created-tags-header-break-point: 430px;

@mixin media-query($from: 0, $to: 65535px, $orientation: null) {
  @if ($orientation) {
    @media only screen and (min-width: $from) and (max-width: $to) and (orientation: $orientation) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $from) and (max-width: $to) {
      @content;
    }
  }
}

@mixin content-padding {
  @media only screen and (min-width: $l-break-point) {
    padding-left: vars.$app-content-padding-l;
    padding-right: vars.$app-content-padding-l;

    @content;
  }

  @include media-query($to: $xl-break-point, $orientation: landscape) {
    padding-left: vars.$app-content-padding-landscape;
    padding-right: vars.$app-content-padding-landscape;

    @content;
  }

  @media only screen and (min-width: $xl-break-point) {
    padding-left: vars.$app-content-padding-xl;
    padding-right: vars.$app-content-padding-xl;

    @content;
  }

  @media only screen and (min-width: $xxl-break-point) {
    padding-left: vars.$app-content-padding-xxl;
    padding-right: vars.$app-content-padding-xxl;

    @content;
  }
}

@mixin center-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-center {
  & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
