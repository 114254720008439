@use 'colors';
@use 'screen';
@use 'variables' as vars;
@use 'sass:map';

html,
body {
  background-color: black;
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  line-height: 1;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @media all and (display-mode: browser) and (max-width: screen.$xl-break-point) {
    height: -webkit-fill-available;
  }
}

app-root {
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media all and (display-mode: browser) and (max-width: screen.$xl-break-point) {
    height: -webkit-fill-available;
  }
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  transition-property: background-color;
  transition-duration: vars.$transition-time;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    transition-property: background-color;
    transition-duration: vars.$transition-time;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  border: none;
  background: none;
  height: 22px;

  .default & {
    caret-color: colors.$primary-brand-default;
  }
}

/* ---------- GRID ---------- */

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  grid-column-gap: 29px;

  @include screen.media-query($from: screen.$s-break-point) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
  }
  @include screen.media-query($from: screen.$m-break-point) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
  }

  @include screen.media-query($from: screen.$l-break-point) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 22px;
  }

  @include screen.media-query(
    $to: screen.$xl-break-point,
    $orientation: landscape
  ) {
    &:not(.header-grid) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  @include screen.media-query($from: screen.$xl-break-point) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
  }

  @include screen.media-query($from: screen.$xxl-break-point) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 43px;
  }
}

/* ---------- TYPOGRAPHY ---------- */
@mixin cmv-hx($font-size, $letter-spacing, $font-size-s, $letter-spacing-s) {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  font-weight: 700;

  @include screen.media-query($from: screen.$s-break-point) {
    font-size: $font-size-s;
    letter-spacing: $letter-spacing-s;
  }
}

.cmv-h1 {
  @include cmv-hx(18px, 0.3px, 32px, 0.6px);
}

.cmv-h2 {
  @include cmv-hx(16px, 0.4px, 18px, 0.4px);
}

.cmv-h3 {
  @include cmv-hx(12px, 0.3px, 16px, 0.4px);
}

.cmv-h4 {
  @include cmv-hx(12px, 0.3px, 15px, 0.4px);
}

.cmv-h5 {
  @include cmv-hx(12px, 0.3px, 14px, 0.3px);
}

.cmv-h6 {
  @include cmv-hx(12px, 0.3px, 13px, 0.3px);
}

.cmv-h7 {
  @include cmv-hx(12px, 0.3px, 12px, 0.3px);
}

.cmv-paragraph {
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;

  @include screen.media-query($from: screen.$s-break-point) {
    font-size: 12px;
  }
}

.cmv-listing {
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

  @include screen.media-query($from: screen.$s-break-point) {
    font-size: 12px;
  }
}

.cmv-body {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.3px;
  font-weight: 400;

  @include screen.media-query($from: screen.$s-break-point) {
    letter-spacing: 0.3px;
  }
}

.cmv-body-2 {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.3px;
  font-weight: 400;

  @include screen.media-query($from: screen.$s-break-point) {
    letter-spacing: 0.3px;
  }
}

/* ---------- HEADER ---------- */

.header-wrapper {
  width: 100%;
  height: vars.$header-height-mobile-small;
  z-index: 1000;

  .default & {
    background-color: colors.$primary-brand-default;
  }

  @include screen.media-query($from: screen.$s-break-point) {
    height: vars.$header-height-mobile;
  }

  @media screen and (min-width: screen.$s-break-point) and (orientation: landscape) {
    height: vars.$header-height-mobile-landscape;
  }

  @include screen.media-query($from: screen.$xl-break-point) {
    height: vars.$header-height;
  }
}

/* ---------- SELECT - MORE ---------- */

.cmv-menu-container {
  width: 340px;

  .mat-mdc-menu-content {
    padding: 0;
    border: 2px dotted colors.$cmv-silver;
    background-color: colors.$cmv-gainsboro;

    .cmv-menu-item {
      background-color: colors.$cmv-gainsboro;
      height: 60px;
      border-bottom: 2px dotted colors.$cmv-silver;

      .mat-mdc-menu-item-text {
        width: 100%;
        color: colors.$cmv-dim-gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .mat-icon {
        margin-right: 0;
      }

      .mat-icon:not([color]) {
        color: currentColor;
      }

      &:last-child {
        border-bottom: none;
      }

      &:disabled {
        color: colors.$cmv-silver;
      }

      &:not(:disabled) {
        @include screen.media-query($from: screen.$m-break-point) {
          &:hover {
            background-color: colors.$cmv-light-gray;
          }
        }
      }
    }
  }
}

/* ---------- MODAL ---------- */

.cmv-modal-outer-card {
  width: vars.$modal-width;
  height: vars.$modal-height;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: colors.$cmv-white;
  margin: 0 auto;
  pointer-events: all;

  @include screen.media-query($from: screen.$m-break-point) {
    padding: 12px;
    position: initial;
  }

  @include screen.media-query(
    $to: screen.$xl-break-point,
    $orientation: landscape
  ) {
    width: vars.$modal-width-landscape;
    height: vars.$modal-height-landscape;
    position: initial;
  }
}

.cmv-modal-inner-card {
  width: 100%;
  height: 100%;
  border: 2px dotted colors.$cmv-silver;
  flex-direction: column;
  color: colors.$cmv-gray;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include screen.media-query($from: screen.$m-break-point) {
    padding: 0px 15px;
  }

  @include screen.media-query(
    $to: screen.$xl-break-point,
    $orientation: landscape
  ) {
    overflow: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .main-info {
    & {
      flex-direction: column;
    }

    .icon-wrapper {
      width: vars.$modal-big-icon-size-mobile;
      height: vars.$modal-big-icon-size-mobile;
      margin-bottom: 20px;

      @include screen.media-query($from: screen.$m-break-point) {
        width: vars.$modal-big-icon-size;
        height: vars.$modal-big-icon-size;
      }

      .modal-icon {
        width: 100%;
        height: 100%;
      }
    }

    .subtitle {
      font-weight: 600;
      margin: 8px 0;
      max-width: 75%;
      text-align: center;
      line-height: 1.4;

      @include screen.media-query($from: screen.$m-break-point) {
        max-width: initial;
      }
    }

    @include screen.center-center;
  }

  .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include screen.media-query($from: screen.$m-break-point) {
      flex-direction: row;
      justify-content: space-around;
      padding: 0 25px 25px 25px;
    }

    > * {
      width: 100%;

      @include screen.media-query($from: screen.$m-break-point) {
        width: 40%;
      }
    }
  }
}

.cmv-modal-remove {
  .main-info {
    margin: auto 0;
  }
}

.cmv-modal-close-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 22px;
  right: 22px;
  cursor: pointer;

  @include screen.media-query($from: screen.$m-break-point) {
    &:hover {
      transition: all 500ms;
      transform: rotate(90deg);
    }
  }
}

@each $name in map.keys(colors.$cmv-colors) {
  .#{$name}-icon {
    color: map.get(colors.$cmv-colors, $name);
  }
}

/* ---------- INPUT ---------- */

.cmv-input {
  width: 100%;
  text-align: center;

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-form-field-infix {
    border-bottom: 2px dotted colors.$cmv-silver;
    padding-bottom: 9px !important;
    font-size: 18px;
    font-family: 'Roboto Condensed', sans-serif;
    letter-spacing: 0.4px;
    text-align: center;
    font-weight: 700;
    color: colors.$cmv-dark-gray;

    input {
      text-align: inherit;
    }

    .mat-mdc-floating-label,
    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      left: 50% !important;
      transform: translate(-50%, -50%);
      &.mdc-floating-label--float-above {
        transform: translate(-37.5%, -106%) scale(0.75);
      }
    }
  }
}

.cmv-input-suffix {
  vertical-align: baseline;
  color: colors.$cmv-silver;
}

.mat-mdc-input-element {
  vertical-align: baseline;
}

.cmv-input.mat-form-field.mat-focused .mat-mdc-form-field-label,
.cmv-textarea.mat-form-field.mat-focused .mat-mdc-form-field-label {
  color: colors.$cmv-gray;
}

.cmv-label,
.mat-mdc-form-field-label {
  color: colors.$cmv-dim-gray !important;
}

.cmv-radio {
  .mat-mdc-radio-button {
    margin: 0 20px 0 0;
    font-size: 18px;
    font-family: 'Roboto Condensed', sans-serif;
    letter-spacing: 0.4px;
    font-weight: 700;
    color: colors.$cmv-gray;
  }
}

div.mat-mdc-select-panel[role='listbox'] {
  min-width: 100% !important;
  padding: 0;
}

.mat-mdc-select-value-text {
  margin: 0 20px 0 0;
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: colors.$cmv-dark-gray;

  .mat-mdc-select-disabled & {
    color: rgba(0, 0, 0, 0.38);
  }
}

.mat-mdc-slide-toggle {
  .mdc-form-field {
    font-size: inherit;

    .mdc-switch--disabled + label {
      opacity: 0.38;
    }

    .mdc-label {
      font-weight: 700;
      font-size: inherit;
      letter-spacing: 0.3px;
      color: #6f6f6f;
    }
    .mdc-switch {
      margin-left: 24px;
    }
  }
}

.cmv-label-center {
  .mat-mdc-form-field-flex {
    display: flex;
    justify-content: center;
  }

  .mat-mdc-form-field-label-wrapper {
    transition:
      transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translateY(0) scale(1) perspective(100px) translateZ(0.001px);
  }

  &.mat-mdc-form-field-appearance.mat-mdc-form-field-can-float
    .mat-mdc-input-server:focus
    + .mat-mdc-form-field-label-wrapper,
  &.mat-mdc-form-field-appearance.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
    .mat-mdc-form-field-label-wrapper {
      transform: translateY(-1.28125rem) scale(0.75) perspective(100px)
        translateZ(0.001px) !important;
    }

    .mat-mdc-form-field-label {
      transform: perspective(100px) !important;
    }
  }

  label {
    width: 100% !important;
  }
}

.default {
  // Common
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
    color: colors.$primary-brand-default;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: transparent;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
    background-color: colors.$primary-brand-default;
  }

  // Datepicker
  .mat-mdc-datepicker-toggle-active {
    color: colors.$primary-brand-default;
  }

  .mat-mdc-calendar-body-selected {
    background-color: colors.$primary-brand-default;
  }

  // Select
  .mat-mdc-primary
    .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
    color: colors.$primary-brand-default;
  }

  .mat-mdc-primary .mat-mdc-pseudo-checkbox-checked,
  .mat-mdc-primary .mat-mdc-pseudo-checkbox-indeterminate {
    background-color: colors.$primary-brand-default;
  }

  // Radio
  .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle {
    background-color: colors.$primary-brand-default;
  }

  .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked
    .mat-mdc-radio-outer-circle {
    border-color: colors.$primary-brand-default;
  }

  .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
  .mat-mdc-radio-button.mat-mdc-accent
    .mat-radio-ripple
    .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
  .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked
    .mat-mdc-radio-persistent-ripple,
  .mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple {
    background-color: colors.$primary-brand-default;
  }
}

/* ---------- OTHER ---------- */

.cmv-section-header {
  position: relative;
  width: 100%;
  color: colors.$cmv-dim-gray;
  min-height: 45px;
  background-color: colors.$cmv-white;

  .default & {
    border-bottom: 2px solid colors.$primary-brand-default;
  }

  &.loading {
    border-bottom-width: 0;
    min-height: 43px;
  }

  &--inner {
    display: flex;
    align-items: center;
    flex: 1 1 0;
  }

  &.with-loading {
    flex-direction: column;

    &.active {
      cmv-loading-bar {
        display: block;
      }

      border-bottom: 0;
    }
  }

  cmv-loading-bar {
    width: 100%;
    display: none;
  }

  @include screen.center-center;
}

.create-tags-more-wrapper {
  position: absolute;
  right: 0;
}

.cmv-drawing-controls-buttons {
  display: flex;
  width: 100%;
  padding: 8px 0 0 8px;
  flex-shrink: 0;
  flex-grow: 0;

  @include screen.media-query($from: screen.$m-break-point) {
    padding: 15px 0 0 15px;
  }

  .cmv-drawing-button-icon {
    width: 18px;
    height: 18px;

    @include screen.media-query($from: screen.$s-break-point) {
      width: 24px;
      height: 24px;
    }
  }
}

.landscape-hide {
  @media screen and (max-height: screen.$minimum-height-landscape-break-point) and (orientation: landscape) {
    display: none;
  }
}

.landscape-overlay {
  display: none;
  font-family: 'Roboto Condensed', sans-serif;

  @media screen and (max-height: screen.$minimum-height-landscape-break-point) and (orientation: landscape) {
    background-color: colors.$cmv-white;
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex !important;

    @include screen.center-center;
  }
}

.fake-video-fullscreen {
  z-index: 59595959;
  background-color: #000000;
  position: fixed !important;
  opacity: 1;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  ::ng-deep .vg-player {
    height: 100vh;
    width: auto;
  }
}

.video-detail-content,
.played-match-content,
.live-tagging-content {
  @media screen and (max-height: screen.$minimum-height-landscape-break-point) and (orientation: landscape) {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}

/* ---------- BUTTONS ---------- */

// Change padding only for not real buttons
// This is fucked up in whole app, because of wrong decision early
div.cmv-default-button {
  padding: 14px 25px;
}

.cmv-default-button {
  cursor: pointer;
  user-select: none;
  height: 50px;

  @include screen.media-query($from: screen.$s-break-point) {
    height: 40px;
  }

  @include screen.center-center;
}

.cmv-small-button {
  @extend .cmv-default-button;
  height: 30px;

  background-color: colors.$secondary-brand;

  .default & {
    color: colors.$primary-brand-default;
    border: 2px solid colors.$primary-brand-default;
  }

  @include screen.media-query($from: screen.$l-break-point) {
    display: flex;

    &:hover {
      color: colors.$cmv-white;

      .default & {
        background-color: colors.$primary-brand-default;
      }
    }
  }
}

.cmv-log-button {
  transition: vars.$transition-time;
  background-color: transparent;

  .default & {
    color: colors.$primary-brand-default;
    border: 2px solid colors.$primary-brand-default;
  }

  @extend .cmv-default-button;

  @include screen.media-query($from: screen.$l-break-point) {
    display: flex;

    &:hover {
      color: colors.$cmv-white;

      .default & {
        background-color: colors.$primary-brand-default;
      }
    }
  }
}

.cmv-toggle-button {
  @extend .cmv-log-button;
  background-color: transparent;
  font-weight: bold;
  text-transform: none;
  font-size: 14px;
  height: 35px;

  @include screen.media-query($from: screen.$s-break-point) {
    height: 40px;
  }
}

.cmv-edit-button {
  @extend .cmv-log-button;
  background-color: transparent;
  font-weight: bold;
  text-transform: none;
  font-size: 14px;
  height: 35px;
  width: 35px;
  padding: 0 !important;

  @include screen.media-query($from: screen.$s-break-point) {
    height: 40px;
    width: 40px;
  }
}

.mat-icon {
  pointer-events: none;
}

.cmv-undo-button {
  @extend .cmv-default-button;
  padding-left: 10px;
  padding-right: 10px;
  color: colors.$cmv-blue-grey;
  font-weight: bold;
  text-transform: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 24px;
  height: 35px;

  @include screen.media-query($from: screen.$s-break-point) {
    height: 40px;
  }

  .mat-icon {
    width: 16px;
    margin-right: 12px;
  }
}

.cmv-dashed-button {
  border: 2px dotted colors.$cmv-silver;
  color: colors.$cmv-dim-gray;
  width: fit-content;
  justify-content: space-between;
  height: 50px;

  &:hover {
    background-color: colors.$cmv-light-gray;
  }

  @extend .cmv-default-button;

  .text {
    min-width: 125px;
  }

  .button-icon {
    margin-left: 60px;
    max-width: 24px;
    max-height: 24px;
  }
}

.cmv-accept-button {
  color: colors.$cmv-white;
  transition: transform vars.$transition-time;

  .default & {
    background-color: colors.$primary-brand-default;
  }

  @include screen.media-query($from: screen.$xl-break-point) {
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

  @extend .cmv-default-button;
}

.cmv-decline-button {
  color: colors.$cmv-dark-gray;
  transition: transform vars.$transition-time;

  @include screen.media-query($from: screen.$m-break-point) {
    border: 2px solid colors.$cmv-dark-gray;
  }

  @include screen.media-query($from: screen.$xl-break-point) {
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

  @extend .cmv-default-button;
}

.not-implemented {
  cursor: default;
  opacity: 0.8;
  text-decoration: line-through;
}

/* Angular Material styling */

.small-device-resolution-tagging {
  .mat-mdc-tab {
    flex: 1 1 0;
    padding: 0;
    background-color: colors.$cmv-white;
    color: colors.$cmv-dark-gray;
    border-bottom: 2px solid colors.$cmv-dark-gray;
    opacity: 1;
  }

  .mdc-tab__content {
    display: flex;
    flex-grow: 1;
  }

  .mat-mdc-tab-label-active {
    color: colors.$cmv-dim-gray;
  }

  .mat-mdc-tab-list {
    .mat-mdc-ink-bar {
      .default & {
        background-color: colors.$primary-brand-default;
      }
    }
  }
}

.cdk-overlay-container {
  .mat-mdc-dialog-container {
    padding: 0;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: none;
    box-shadow: none;
    pointer-events: none;
  }
}

/* ---- Match list tabs ----- */

.match-list-group-wrapper {
  .mat-mdc-tab-group {
    @extend .grid-container;
    grid-template-rows: max-content;
    grid-template-columns: repeat(12, 1fr);

    @include screen.media-query($from: screen.$s-break-point) {
      grid-template-columns: repeat(12, 1fr) 5%;
    }
  }

  .mat-mdc-tab-header {
    grid-column: 1 / -1;

    @include screen.media-query($from: screen.$l-break-point) {
      padding: vars.$platform-padding-top-mobile 5% 0 5%;
    }

    @include screen.media-query($from: screen.$xl-break-point) {
      padding-top: vars.$platform-padding-top;
    }

    @include screen.media-query(
      $from: screen.$l-break-point,
      $to: screen.$xl-break-point,
      $orientation: landscape
    ) {
      padding-left: vars.$app-content-padding-landscape;
      padding-right: vars.$app-content-padding-landscape;
    }
  }

  .mat-mdc-tab-header-pagination {
    background-color: colors.$cmv-white;
    border-bottom: 2px solid colors.$cmv-dark-gray;
  }

  .mat-mdc-tab-body-wrapper {
    grid-column: 1 / -1;
  }

  .mat-mdc-tab-header {
    border-bottom: none;
  }

  .mat-mdc-tab-body {
    .matches-list {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .mat-mdc-tab-label-container {
    background-color: colors.$cmv-white;

    .mat-mdc-tab {
      height: 100%;
      color: colors.$cmv-dark-gray;
      opacity: 1;
      flex-grow: 1;
      user-select: none;
      -webkit-user-select: none;
      min-width: 160px;

      @include screen.media-query($from: screen.$l-break-point) {
        flex-grow: 0 !important;
      }

      @include screen.media-query(
        $to: screen.$xl-break-point,
        $orientation: landscape
      ) {
        height: 38px;
        font-size: 12px;
      }

      &:last-child {
        min-width: 0;
        padding: 0;
        pointer-events: none;
        flex-grow: 1;
        display: none;

        @include screen.media-query($from: screen.$l-break-point) {
          display: flex;
        }
      }
    }

    .mdc-tab--active {
      color: colors.$cmv-dim-gray;
    }
  }

  .mat-mdc-tab-list {
    .mat-mdc-ink-bar {
      .default & {
        background-color: colors.$primary-brand-default;
      }
    }
  }

  .tab-content {
    height: 100%;
  }

  .mat-mdc-tab-label {
    padding: 0;

    &-content,
    &-wrapper {
      width: 100%;
      height: 100%;
    }

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mat-mdc-tab-labels {
    border-bottom: 2px solid colors.$cmv-dark-gray;

    .mat-mdc-tab,
    & {
      height: 45px;
    }
  }
}

/* ---- More view tabs ----- */

.more-mat-group-wrapper {
  .mat-mdc-tab-header-pagination {
    background-color: colors.$cmv-white;
    border-bottom: 2px solid colors.$cmv-dark-gray;
  }

  .mat-mdc-tab-header {
    border-bottom: none;
  }

  .mat-mdc-tab-label-container {
    background-color: colors.$cmv-white;

    .mat-mdc-tab-label {
      background-color: colors.$cmv-white;
      color: colors.$cmv-dark-gray;
      border-bottom: 2px solid colors.$cmv-dark-gray;
      opacity: 1;
      flex-grow: 1;

      @include screen.media-query($from: screen.$l-break-point) {
        flex-grow: 0;
      }
    }

    .mat-mdc-tab-label:last-child {
      min-width: 0;
      padding: 0;
      pointer-events: none;
      flex-grow: 1;
      display: none;

      @include screen.media-query($from: screen.$l-break-point) {
        display: flex;
      }
    }

    .mat-mdc-tab-label-active {
      color: colors.$cmv-dim-gray;
    }
  }

  .mat-mdc-tab-body {
    width: 100%;
    margin-top: 12px;
    min-height: 100%;

    @include screen.media-query($from: screen.$s-break-point) {
      margin-top: 24px;
    }

    @include screen.media-query($from: screen.$l-break-point) {
      margin-top: 42px;
      max-width: 643px;
    }

    .mat-mdc-tab-body-content {
      overflow: hidden;

      @include screen.media-query($from: screen.$l-break-point) {
        padding-right: vars.$app-content-padding-l;
      }

      @include screen.media-query($from: screen.$xl-break-point) {
        padding-right: vars.$app-content-padding-xl;
      }

      @include screen.media-query($from: screen.$xxl-break-point) {
        padding-right: vars.$app-content-padding-xxl;
      }
    }
  }

  .mat-mdc-tab-list {
    .mat-mdc-ink-bar {
      .default & {
        background-color: colors.$primary-brand-default;
      }
    }
  }
}

.mat-mdc-menu-trigger {
  img {
    --mdc-icon-button-icon-size: 17px;
  }
}

/* --- MAT MODAL MENU --- */

.cdk-overlay-pane {
  &.app-modal-overlay-panel {
    width: 100%;
    height: 100%;
    max-width: unset !important;
    pointer-events: none;

    .mat-mdc-dialog-container {
      background: none;
      transition: none;
    }
  }

  .mat-mdc-menu-panel {
    max-width: 340px;
    box-shadow: none;
  }

  .mat-mdc-menu-item {
    .mat-mdc-icon {
      margin-right: 2px;
    }
  }

  .mat-mdc-dialog-container {
    box-shadow: none;
    overflow: initial;

    @include screen.media-query($from: screen.$l-break-point) {
      overflow: auto;
    }
  }
}

.cmv-button-with-spinner {
  .mat-mdc-button-wrapper,
  .button-wrapper {
    display: flex;
    align-items: center;
  }

  .button-spinner {
    $size: 20px;
    height: $size !important;
    width: $size !important;
    margin-left: 8px;

    svg {
      height: $size !important;
      width: $size !important;
    }
  }
}

::-webkit-scrollbar-track {
  background-color: colors.$cmv-white !important;
}

::-webkit-scrollbar {
  display: none;
  width: 4px;
  background-color: colors.$cmv-white !important;

  @include screen.media-query($from: screen.$l-break-point) {
    display: block;
  }
}

.default {
  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: colors.$cmv-white
      linear-gradient(
        to bottom,
        colors.$primary-brand-default 0%,
        colors.$primary-brand-default 100%
      ) !important;
  }
}

.source-checkmark-icon-wrapper {
  .default & {
    color: colors.$primary-brand-default;
  }
}

.button-spinner {
  circle {
    .default & {
      /* stylelint-disable-line selector-max-type */
      stroke: colors.$primary-brand-default;
    }
  }
}

.cdk-virtual-scroll-content-wrapper {
  margin-top: 24px;

  @include screen.media-query($from: screen.$s-break-point) {
    margin-top: 18px;
  }
}

/* MISSING VIDEO */

.cmv-16-9-ratio-container {
  position: relative;
  width: 100%;
  background-color: #000;
}

.cmv-16-9-ratio-container::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.cmv-not-in-range {
  position: absolute;
  text-align: center;
  padding: 0 20px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: colors.$cmv-white;
  background-color: colors.$cmv-gray;
  flex-direction: column;

  :first-child {
    margin-bottom: 10px;
  }

  @include screen.center-center;
}

.cmv-not-in-range-title {
  margin-bottom: 5px;
}

.cmv-error-component {
  z-index: 600;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ---------- RECORDING FORM ---------- */

.cmv-recording-form-wrap {
  height: calc(100% - #{vars.$header-height-mobile-small});
  background-color: colors.$cmv-gainsboro;
  padding-top: vars.$platform-padding-top-mobile;
  overflow-x: hidden;
  overflow-y: auto;

  @include screen.media-query($from: screen.$s-break-point) {
    padding-top: vars.$platform-padding-top;
    height: calc(100% - #{vars.$header-height-mobile});
  }

  @include screen.media-query($from: screen.$l-break-point) {
    padding: vars.$platform-padding-top vars.$app-content-padding-l 0
      vars.$app-content-padding-l;
    height: calc(100% - #{vars.$header-height});
  }

  @include screen.media-query(
    $to: screen.$xl-break-point,
    $orientation: landscape
  ) {
    height: calc(100% - #{vars.$header-height-mobile-landscape});
  }
}

.cmv-recording-form-grid {
  @include screen.media-query($to: screen.$l-break-point) {
    padding-top: vars.$platform-padding-top-mobile;
  }
}

/* ---------- OTHER ---------- */

.cmv-video-widget-container {
  &:not(:first-child) {
    margin-top: 4px;
  }
}

.cmv-tags-container {
  background-color: colors.$cmv-white;
  flex: 1;
  overflow-y: auto;
}

.input-gray-icon {
  svg {
    fill: colors.$cmv-silver;
  }
}

.lowercase {
  text-transform: lowercase;
}

.button.button-icon {
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
}

.team-search .mat-mdc-form-field-label-wrapper {
  position: absolute !important;
  box-sizing: border-box !important;
  top: -28px;
  padding-top: 0;
}

.team-search .mat-mdc-form-field-label,
.cmv-label {
  font-size: 13px;
  display: block !important;
  width: 100% !important;
  margin-bottom: 5px;
  transform: none !important;
  min-height: 14px;
}

.cmv-input-without-label {
  padding-top: 19px;
}

.input-right-icon {
  position: absolute;
  right: 0;
  bottom: 9px;
  width: 24px;
  height: 22px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &.mat-mdc-datepicker-toggle {
    right: auto;
    left: 0;
    bottom: -28px;
  }

  svg {
    fill: colors.$cmv-silver;
    height: 16px;
    width: 16px;
  }
}

.cmv-number-input-without-controls {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.mat-mdc-select-arrow {
  height: 24px !important;
  width: 13px !important;
  border: none !important;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    background-color: colors.$cmv-silver;
    position: absolute;
    top: 12px;
  }

  &:before {
    left: 0;
    transform: rotate(45deg);
  }

  &:after {
    right: 0;
    transform: rotate(-45deg);
  }
}

/** CUSTOM DATE RANGE PICKER **/
// origin: saturn-datepicker/bundle.css
:not(.mat-mdc-calendar-body-disabled):hover
  > .mat-mdc-calendar-body-cell-content:not(
    .mat-mdc-calendar-body-selected
  ):not(.mat-mdc-calendar-body-semi-selected),
.cdk-keyboard-focused
  .mat-mdc-calendar-body-active
  > .mat-mdc-calendar-body-cell-content:not(
    .mat-mdc-calendar-body-selected
  ):not(.mat-mdc-calendar-body-semi-selected),
.cdk-program-focused
  .mat-mdc-calendar-body-active
  > .mat-mdc-calendar-body-cell-content:not(
    .mat-mdc-calendar-body-selected
  ):not(.mat-mdc-calendar-body-semi-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}
:not(.mat-mdc-calendar-body-disabled):hover
  > .mat-mdc-calendar-body-semi-selected,
.cdk-keyboard-focused
  .mat-mdc-calendar-body-active
  > .mat-mdc-calendar-body-semi-selected,
.cdk-program-focused
  .mat-mdc-calendar-body-active
  > .mat-mdc-calendar-body-semi-selected {
  .default & {
    background-color: colors.$primary-brand-default;
  }
  color: white;
}
.mat-mdc-calendar-body-begin-range:not(.mat-mdc-calendar-body-end-range) {
  border-radius: 100% 0 0 100%;
  .default & {
    background-color: colors.$primary-brand-default-lighten;
  }
}
.mat-mdc-calendar-body-end-range:not(.mat-mdc-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0;
  .default & {
    background-color: colors.$primary-brand-default-lighten;
  }
}
.mat-mdc-calendar-body
  > tr
  .mat-mdc-calendar-cell-semi-selected
  ~ .mat-mdc-calendar-cell-semi-selected {
  border-radius: 0;
}
.mat-mdc-calendar-cell-semi-selected {
  .default & {
    background-color: colors.$primary-brand-default-lighten;
  }
}
.mat-mdc-calendar-cell-over > .mat-mdc-calendar-body-cell-content {
  .default & {
    border: colors.$primary-brand-default 1px solid;
  }
}

.cmv-video-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 73px;
  background-image: linear-gradient(
    to bottom,
    rgba(17, 22, 34, 0.5),
    rgba(17, 22, 34, 0)
  );
  background-color: transparent;
  z-index: 202;
  display: flex;
  pointer-events: none !important;
  justify-content: flex-end;
  // hide class is added automatically by the videoplayer
  &.hide {
    opacity: 0;
  }
}

.cmv-underline {
  text-decoration: underline;
  cursor: pointer;
}

.cmv-terms-menu {
  span {
    font-weight: 600;
    cursor: pointer;
  }
}

.cmv-document-header {
  margin-top: 16px;
}

.cmv-document-main-heading {
  line-height: 1.5;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 32px;
  font-weight: 700;

  @include screen.media-query($from: screen.$s-break-point) {
    font-size: 48px;
    letter-spacing: 0.4px;
  }
}

.cmv-document-section {
  text-transform: uppercase;
  margin-top: 16px;
}

.cmv-document-table {
  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }

  table tr td {
    display: table-cell;
    padding: 8px;
    vertical-align: top;
    text-align: left;
    border-top: 1px solid colors.$cmv-dim-gray;
    font-size: 12px;
    line-height: 1.5;
  }
}

.rotate-message {
  display: none;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #309fe3;
}

.mdc-linear-progress__buffer {
  background-color: #e6e6e6;
}

html {
  --mdc-linear-progress-active-indicator-height: 8px;
  --mdc-linear-progress-track-height: 8px;
  --mdc-filled-text-field-input-text-placeholder-color: #0000006b;
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;
  --mdc-filled-text-field-input-text-color: $cmv-color-gray;
  --mdc-filled-text-field-error-label-text-color: #4b4b4b;
  --mdc-filled-text-field-error-focus-label-text-color: #4b4b4b;
  --mdc-filled-text-field-focus-label-text-color: #4b4b4b;
  --mdc-filled-text-field-label-text-color: #4b4b4b;
  --mdc-filled-text-field-caret-color: #309fe3;
  --mat-datepicker-calendar-date-selected-state-background-color: #309fe3;
  --mdc-filled-text-field-disabled-container-color: transparent;
  --mat-option-selected-state-label-text-color: #309fe3;
  --mat-expansion-header-indicator-color: inherit;
  --mat-select-enabled-arrow-color: transparent;

  .mat-primary {
    --mat-full-pseudo-checkbox-selected-icon-color: #309fe3;
  }

  .mat-mdc-slide-toggle {
    --mdc-switch-selected-handle-color: #309fe3;
    --mdc-switch-selected-focus-handle-color: #309fe3;
    --mdc-switch-selected-hover-handle-color: #309fe3;
    --mdc-switch-selected-pressed-handle-color: #309fe3;
    --mdc-switch-unselected-handle-color: #fafafa;
    --mdc-switch-unselected-pressed-handle-color: #fafafa;
    --mdc-switch-unselected-focus-handle-color: #fafafa;
    --mdc-switch-unselected-hover-handle-color: #fafafa;

    --mdc-switch-selected-track-color: #309fe3;
    --mdc-switch-selected-focus-track-color: #309fe3;
    --mdc-switch-selected-hover-track-color: #309fe3;
    --mdc-switch-selected-pressed-track-color: #309fe3;
    --mdc-switch-unselected-pressed-track-color: #00000061;
    --mdc-switch-unselected-hover-track-color: #00000061;
    --mdc-switch-unselected-pressed-track-color: #00000061;
    --mdc-switch-unselected-track-color: #00000061;

    --mdc-switch-handle-surface-color: #fafafa;
    --mdc-switch-disabled-unselected-handle-color: #fafafa;
  }

  .mat-mdc-tab-group {
    --mdc-tab-indicator-active-indicator-color: #309fe3;
    --mat-tab-header-active-focus-indicator-color: #309fe3;
    --mat-tab-header-active-hover-indicator-color: #309fe3;
    --mat-tab-header-active-label-text-color: #4b4b4b;

    --mat-tab-header-active-ripple-color: rgba(0, 0, 0, 0.25);
    --mat-tab-header-inactive-ripple-color: rgba(0, 0, 0, 0.05);
    --mat-tab-header-active-hover-label-text-color: #4b4b4b;
    --mat-tab-header-active-focus-label-text-color: #4b4b4b;
  }

  .select-panel {
    --mat-select-panel-background-color: #3d475f;

    .mdc-list-item__primary-text {
      --mat-option-selected-state-label-text-color: #fff;
      color: #96aec3;
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }

  div.mat-mdc-autocomplete-panel {
    padding: 0;

    .mat-mdc-option .mdc-list-item__primary-text {
      width: 100%;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
      border-bottom: 1px solid #cbcbcb;
      border-radius: 0;
    }

    .mat-mdc-progress-spinner {
      margin: 0 auto;
      margin-bottom: 15px;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-mdc-option .mat-pseudo-checkbox {
      display: none;
    }
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: inherit;

    &.mat-mdc-button-base {
      padding: 0;
    }
  }
}

.mdc-line-ripple {
  &::before,
  &::after {
    display: none;
  }
}

input[type='search' i]::-webkit-search-cancel-button {
  display: none;
}

.cmv-more-button.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 30px;

  .mat-mdc-button-touch-target {
    width: 30px;
    height: 30px;
  }
}

.created-tags-mobile-loading-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.menu-icon--wrapper {
  display: flex;
}

.more-content {
  background-color: colors.$cmv-gainsboro;
  color: colors.$cmv-dim-gray;
  letter-spacing: 0.3px;
  line-height: 26px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  position: relative;
  height: calc(100% - #{vars.$header-height-mobile-small});
  overflow-x: hidden;
  overflow-y: auto;
  grid-column: 1 / -1;
  padding: 12px 5% vars.$sidebar-height-mobile;

  @include screen.media-query($from: screen.$xl-break-point) {
    grid-column: 2 / -1;
    padding-left: 0;
    padding-right: 0;
  }

  @include screen.content-padding;

  @include screen.media-query($from: screen.$s-break-point) {
    padding-bottom: vars.$sidebar-height-tablet;
    height: calc(100% - #{vars.$header-height-mobile});
    padding-top: 24px;
  }

  @include screen.media-query(
    $to: screen.$xl-break-point,
    $orientation: landscape
  ) {
    height: calc(100% - #{vars.$header-height-mobile-landscape});
  }

  @include screen.media-query($from: screen.$xl-break-point) {
    height: calc(100% - #{vars.$header-height});
    padding-top: 42px;
  }

  .mat-mdc-tab-group {
    grid-column: 1 / -1;

    @include screen.media-query($from: screen.$xxl-break-point) {
      grid-column: 2 / -1;
    }
  }
}

.more-content-html-elements-override {
  @extend .more-content;

  /* stylelint-disable */

  p {
    margin: 1.2em 0;
  }

  ul {
    list-style: disc;
    margin: 0;
    padding: 0;
  }

  ul.firstul li {
    font-weight: bold;
  }

  ul li {
    margin-top: 0 !important;
    margin-left: 16px;
  }

  ul li a,
  ul li a:link,
  ul li a:visited {
    text-decoration: none;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }

  table tr td {
    display: table-cell;
    padding: 8px;
    vertical-align: top;
    text-align: left;
    border-top: 1px solid colors.$cmv-dim-gray;
    font-size: 12px;
    line-height: 1.5;
  }

  ol {
    margin-top: 0 !important;
  }
}

.more-question {
  margin-bottom: 5px;
  font-weight: 600;
}

.more-paragraph {
  margin-bottom: 22px;
  padding: 0 vars.$app-content-padding-l;

  @include screen.media-query($from: screen.$s-break-point) {
    padding: 0;
  }
}

.white-floating-bar {
  height: 48px;
  border-bottom: 2px solid colors.$cmv-dark-gray;
  width: calc(2px + #{vars.$app-content-padding-l});
  background-color: colors.$cmv-white;
  position: absolute;
  left: calc(100% - #{vars.$app-content-padding-l} - 2px);
  top: 12px;

  @include screen.media-query($from: screen.$s-break-point) {
    top: 24px;
  }

  @include screen.media-query($from: screen.$l-break-point) {
    top: 42px;
  }
}
