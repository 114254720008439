$header-height-mobile-small: 50px;
$header-height-mobile: 68px;
$header-height-mobile-landscape: 50px;
$header-height: 68px;

$sidebar-height-mobile: 50px;
$sidebar-height-tablet: 60px;
$sidebar-height-landscape: 46px;
$sidebar-width: 200px;
$collapsed-sidebar-width: 70px;

$search-input-wrap-height-es: 50px;
$search-input-wrap-height-s: 70px;
$search-input-wrap-height-landscape: 50px;
$search-input-wrap-height-l: 120px;

$app-content-padding-xxl: 5%;
$app-content-padding-xl: 5%;
$app-content-padding-l: 5%;
$app-content-padding-landscape: 2%;

$separator-margin-right: 0;
$separator-height: 34px;
$separator-height-landscape: 14px;
$separator-height-mobile: 24px;

$input-padding: 24px;

$match-widget-margin: 12px;
$match-widget-margin-mobile: 6px;

$match-widget-height: 95px;
$match-widget-height-landscape: 50px;
$match-widget-height-mobile: 85px;

$widget-height: 90px;
$widget-height-mobile: 70px;

$platform-padding-top: 24px;
$platform-padding-top-mobile: 12px;

$video-detail-height: 48px;

$trimming-button-size: 48px;

$snackbar-height: 65px;
$snackbar-border-width-mobile: 4px;
$snackbar-border-width: 3px;

$modal-width: 550px;
$modal-height: 550px;
$modal-small-height: 450px;
$modal-width-landscape: 80%;
$modal-height-landscape: 90%;
$modal-height-mobile: 350px;
$modal-padding: 12px;
$modal-big-icon-size: 45px;
$modal-big-icon-size-mobile: 32px;
$modal-icon-top-margin: 65px;
$modal-icon-top-margin-landscape-mobile: 40px;

$mobile-logout-button-small: 80px;
$mobile-logout-button: 110px;
$tablet-logout-button: 120px;

$transition-time: 250ms;

$footer-height: 70px;
